import styled from "styled-components";

const Section = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  position: relative;
  overflow: hidden;
`;

export default Section;
